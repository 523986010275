import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import './App.css';

function parseFileListing(page: string): Array<string> {
  const images: Array<string> = [];
  const lcPage = page.toLowerCase();
  let n = -1;
  // Find images in quotes, "imagename.jpg"
  while ((n = lcPage.indexOf('.jpg"', n+1)) >= 0) {
    let before = page.lastIndexOf('"', n);
    let image = page.substring(before + 1, n + '.jpg'.length);
    images.push(image);
  }
  
  return images;
}

function Gallery(props: {
  galleryPath?: string;
}) {
  const [images, setImages] = useState<Array<string>>();
  useEffect(() => {
    if (props.galleryPath) {
      setImages(undefined);
      fetch(props.galleryPath)
        .then((rsp) => {
          if (rsp.status == 200) {
            rsp.text().then(text => {
              var images = parseFileListing(text);
              setImages(images.map(imgName => {
                return props.galleryPath!.endsWith('/') ? props.galleryPath! + imgName : props.galleryPath! + '/' + imgName;
              }));
            })
            .catch((err) => {
              console.log('Error reading page content', err);
              setImages([]);
            })
          } else {
            console.log('Error fetch response: ' + rsp.status + ' ' + rsp.statusText);
          }
        })
        .catch((err) => {
          console.log('Error fetching page', err);
          setImages([]);
        });
    } else {
      setImages([]);
    }
  }, [props.galleryPath]);
  return <div className="Gallery-main">
    {images == null ? 'Loading...' : images.map((imagePath) => <img key={imagePath} className="Gallery-image" src={imagePath}></img>)}
  </div>;
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={'logo400.jpg'} className="App-logo" alt="betweenTribes" />
      </header>
      <div className="App-body">
      <Route render={(props) => <Gallery galleryPath={props.location.pathname}></Gallery>}>
      </Route>
      </div>
    </div>
  );
}

export default App;
